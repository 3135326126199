<template>
  <section class="app-ecommerce-details">
    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">Alert</h4>
      <div class="alert-body">
        <ul v-for="(values, index) in errors_back" :key="index">
          <li v-for="(value, indexVal) in values" :key="indexVal">
            {{ value }}
          </li>
        </ul>
      </div>
    </b-alert>
    <b-card>
      <b-row>
        <b-col cols="12" v-if="itemData">
          <b-row>
            <b-col md="1">
              <div class="d-flex justify-content-center align-items-center">
                <b-img fluid :src="itemData.cover"></b-img>
              </div>
            </b-col>
            <b-col class="my-auto" md="9">
              <h4>
                <span style="color: #7367f0">name :</span>
                {{ itemData.name }}
              </h4>
              <br />

              <br />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card>
    <b-card-code title="Update " :before-change="validateForm" v-if="itemData">
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row v-for="(value, index) in itemData.values" :key="index">
            <b-col md="6">
              <b-row>
                <b-col md="12" class="d-flex">
                  <h5>
                    {{ value.name }}
                  </h5>
                  <div
                    class="rounded-circle"
                    style="height: 23px; width: 24px; margin-left: 8px"
                    :style="
                      value.hex_code != null
                        ? 'background-color:' + value.hex_code + ';'
                        : ''
                    "
                  ></div>
                </b-col>
                <!-- select values box -->
                <b-col :md="12" v-if="value.new == true">
                  <v-select
                    v-model="value.name"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    :value="itemValues.name"
                    :options="itemValues"
                    @input="updateSelectedValues($event, index)"
                  />
                </b-col>

                <b-col md="12">
                  <b-form-group label="Image" :label-for="'image' + index">
                    <validation-provider
                      #default="{ errors }"
                      :name="'image' + index"
                    >
                      <b-form-file
                        v-model="file"
                        @change="ChangeImageValue($event, index)"
                        :state="errors.length > 0 ? false : null"
                        accept="image/*"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="5">
              <CoolLightBox
                :items="imagesArr"
                :index="imgIndex"
                @close="imgIndex = null"
              >
              </CoolLightBox>
              <b-img
                @click="imgIndex = index"
                class="w-auto"
                style="max-width: 75%"
                :src="value.file"
                v-if="value.file != null"
              />
            </b-col>
            <!-- Remove Button -->
            <b-col md="1" class="mb-50">
              <b-button
                v-if="value.new == true"
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                variant="outline-danger"
                @click="removeValue(index, value)"
              >
                <feather-icon icon="XIcon" class="mr-25" />
              </b-button>
            </b-col>
            <b-col cols="12"> <hr /> </b-col>
          </b-row>
          <!-- add new button -->
          <b-col sm="2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-primary"
              block
              @click="addNewValue"
            >
              <feather-icon icon="PlusIcon" class="mr-25" />
              <span>Add New</span>
            </b-button>
          </b-col>
          <b-col cols="12">
            <hr />
          </b-col>

          <b-col lg="4" md="2" class="my-1">
            <b-button
              v-if="this.updatedValuesData.length > 0"
              variant="primary"
              class="mx-1"
              type="submit"
              @click.prevent="validateForm"
              >Submit</b-button
            >
          </b-col>
        </b-form>
      </validation-observer>
    </b-card-code>
  </section>
</template>
  
  <script>
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import AppEchartLine from '@core/components/charts/echart/AppEchartLine.vue'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import { required, url } from '@validations'
import draggable from 'vuedraggable'
export default {
  name: 'MyComponent',
  components: {
    AppEchartLine,
    Swiper,
    SwiperSlide,
    CoolLightBox,
    draggable,
    AppCollapse,
    AppCollapseItem,
  },
  computed: {
    filteredItems() {
      return this.value.filter((item) => item.file !== null && item.file !== '')
    },
  },
  data() {
    return {
      authPermissions: null,

      file: null,
      required,
      itemData: null,
      itemValues: [],
      imagesArr:[],
      valueData: {
        name: null,
        id: null,
        image: null,
      },
      imgIndex: null,
      updatedValuesData: [],
      errors_back: [],
      showDismissibleAlert: false,
      settings: {
        dots: true,
        focusOnSelect: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        touchThreshold: 5,
      },
      stickyHeader: true,
      noCollapse: true,
      originalValuesLengthData: 0,
      swiperOptions: {
        slidesPerView: 4,
        spaceBetween: 30,
        autoplay: {
          delay: 2500,
          disableOnInteraction: false,
        },
        pagination: {
          el: '.swiper-pagination',
        },
      },
    }
  },
  created() {
    this.authPermissions = this.$store.state.authPermissions
    this.id = this.$route.params.id
    this.fetchData()
  },
  methods: {
    storeCovers() {
      axios
        .post('items-guide/' + this.id + '/imagesWithValues/store', {
          images: this.updatedValuesData,
        })
        .then((result) => {
          this.$swal({
            position: 'center',
            icon: 'success',
            title: 'Your work has been saved',
            showConfirmButton: false,
            timer: 1500,
          })
          // this.$router.back(0)
          this.$router.push({
            path: '/ShowPhoneGuide/' + this.id,
          })
        })
        .catch((err) => {
          this.$swal({
            position: 'center',
            icon: 'error',
            title: 'Error!',
            showConfirmButton: false,
            timer: 1500,
          })
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    ChangeImageValue(e, indexLocale) {
      const image = e.target.files[0]
      if (e != null) {
        this.itemData.values[indexLocale].file = URL.createObjectURL(image)
        this.imagesArr[indexLocale] = this.itemData.values[indexLocale].file
        var fileReader = new FileReader()
        fileReader.readAsDataURL(image)

        var imageData = {}
        fileReader.onload = (e) => {
          imageData.image = e.target.result
            .split(',')
            .map((item) => item.trim())[1]
        }
        imageData.value_id = this.itemData.values[indexLocale].id

        // Check if an object with the same value_id exists in updatedValuesData
        const existingIndex = this.updatedValuesData.findIndex(
          (item) => item.value_id === imageData.value_id
        )

        if (existingIndex !== -1) {
          // If found, replace the existing object
          this.updatedValuesData.splice(existingIndex, 1, imageData)
      
        } else {
          // If not found, create a new object
          this.updatedValuesData.push(imageData)
        }

        // console.log(this.updatedValuesData)
      }
    },
    // submit category API
    async validateForm() {
      await this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          // console.log(this.updatedValuesData)
          this.storeCovers()
        }
      })
    },
    // repeated function
    addNewValue() {
      this.itemData.values.push({
        id: null,
        name: null,
        hex_code: null,
        file: null,
        new: true,
      })
    },
    updateSelectedValues(e, index) {
      this.itemData.values[index].id = e.id
      this.itemData.values[index].name = e.name
      this.itemData.values[index].hex_code = e.hex_code
    },
    async fetchData() {
      this.showDismissibleAlert = false
      await axios
        .get('items-guide/' + this.id + '/coverUpdate/initial')
        .then((result) => {
          this.itemData = result.data.data.item
          this.itemValues = result.data.data.values
          this.imagesArr = result.data.data.item.values
          .map(value => value.file);
            this.originalValuesLengthData = result.data.data.item.values.length

          })
        .catch((err) => {
          console.log(err )
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },

    isString(value) {
      return typeof value === 'string' || value instanceof String
    },
    removeValue(index, value) {
      // return;
      if (this.itemData.values.length > this.originalValuesLengthData) {
        this.itemData.values.splice(index, 1)
        // Check if an object with the same value_id exists in updatedValuesData
        const existingIndex = this.updatedValuesData.findIndex(
          (item) => item.value_id === value.id
        )

        if (existingIndex !== -1) {
          // If found, replace the existing object
          this.updatedValuesData.splice(existingIndex, 1)
        }

        // console.log(index)
        // console.log("after" , this.updatedValuesData)
        // this.upImage.splice(index, 1)
      }
    },
  },
}
</script>
  
  <style lang="scss">
@import '~@core/scss/base/pages/app-ecommerce-details.scss';
</style>
  
  <style>
.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 0px !important;
}
.swiper-container {
  padding: 30px !important;
}
.table-head-specify {
  max-width: 10%;
}
</style>
  